const body = document.body;
const SCROLL_UP_CLASS = "scroll-up";
const SCROLL_DOWN_CLASS = "scroll-down";
let lastScroll = 0;
let lastScrollTimeStamp = new Date().getTime();

const TOC_CONTAINER_WRAPPER_MIN_HEIGHT = 25
const TOC_EXPAND_BTN_MIN_TOP = 12.5

document.onreadystatechange = function () {
  if (document.readyState == "complete") {
    const tocContainerWrapper = document.querySelector('.post-table-of-content__container')
    const tocStickyContainer = document.querySelector('.post-table-of-content__container-sticky')
    const tocExpandBtn = document.querySelector('.post-table-of-content__expand-btn')
    const newNavWrapper = document.querySelector('.new-nav')
    const newNavCategoriesWrapper = document.querySelector('.global-new-nav__header-second-level')

    const resetFloatingTocAlignment = () => {
      const isTocFloatingOverContent = tocContainerWrapper?.classList?.contains('sticky-block--floating')

      if (tocContainerWrapper && !isTocFloatingOverContent) {
        tocStickyContainer.style = ''
        tocExpandBtn.style = ''
      }
    }

    const adjustFloatingTocAlignment = () => {
      const isTocFloatingOverContent = tocContainerWrapper?.classList?.contains('sticky-block--floating')

      if (isTocFloatingOverContent) {
        const newNavTopicsHeight = newNavWrapper.clientHeight
        const newNavCategoriesHeight = (newNavCategoriesWrapper?.clientHeight || 0)

        let offset = newNavCategoriesHeight
        if (!body.classList.contains(SCROLL_DOWN_CLASS)) {
          offset += newNavTopicsHeight
        }

        tocStickyContainer.style = `padding-top: ${TOC_CONTAINER_WRAPPER_MIN_HEIGHT + offset}px;`
        tocExpandBtn.style = `top: ${TOC_EXPAND_BTN_MIN_TOP + offset}px;`
      }
    }

    document.addEventListener('scroll', () => {
      resetFloatingTocAlignment()

      const currentScrollTimeStamp = new Date().getTime();

      const currentScroll = window.pageYOffset;
      if (currentScroll <= 0) {
        body.classList.remove(SCROLL_UP_CLASS);
        return;
      }

      const emptyBackgroundDiv = document.querySelector('.empty-sticky-nav-background-div')
      if(emptyBackgroundDiv) {
        const emptyBackgroundHeight = parseInt(emptyBackgroundDiv.style.height.replace('px', ''))

        if(currentScroll <= emptyBackgroundHeight) {
          body.classList.remove(SCROLL_DOWN_CLASS);
          body.classList.remove(SCROLL_UP_CLASS);
          return
        }
      }

      if (currentScroll > lastScroll && !body.classList.contains(SCROLL_DOWN_CLASS)) {
        body.classList.remove(SCROLL_UP_CLASS);
        body.classList.add(SCROLL_DOWN_CLASS);
        window.dispatchEvent(new CustomEvent('nav-close'))
      } else if (
        currentScroll < lastScroll &&
        body.classList.contains(SCROLL_DOWN_CLASS) &&
        (currentScrollTimeStamp - lastScrollTimeStamp) <= 1000 && 
        (lastScroll - currentScroll) >= 50
      ) {
        body.classList.remove(SCROLL_DOWN_CLASS);
        body.classList.add(SCROLL_UP_CLASS);
        window.dispatchEvent(new CustomEvent('nav-open'))
      }

      lastScroll = currentScroll;
      lastScrollTimeStamp = currentScrollTimeStamp;

      adjustFloatingTocAlignment()
    })
  }
}